<template>
  <div class="claims-page">
    
    <!-- grey intro -->
    <div class="bg-site-light pt-4 pb-4 mb-4">
      <div class="container">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
          <div class="flex-sm-fill">
            <h1 class="h3 font-w700 mb-2">Claims</h1>
            <h2 class="h6 font-w500 text-muted mb-0">
              More stuff/info etc...
            </h2>
          </div>
          <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">
            <a class="btn btn-outline-secondary mr-1"><b-icon icon="plus-square-fill"></b-icon> some text</a>
            <a href="javascript:void(0)" class="btn btn-secondary"><b-icon icon="gear-fill"></b-icon></a>
          </div>
        </div>
      </div>
    </div>

    <div class="px-md-3">
      
      <div class="container-fluid pt-3 pb-1 mb-5 bg-white shadow">
        
        <b-table
          ref="table"
          responsive
          hover
          :items="claims"
          :fields="table_fields"
          @row-clicked="tableClickedRow"
        >
          <template #cell(sentStatus)="data">
            <div class="d-flex justify-content-center">
              <div
                v-bind:class="{
                  'text-center d-flex justify-content-center d-lg-inline-flex': true,
                  'badge badge-primary text-wrap': data.value == 'Sent',
                  'badge badge-danger text-wrap': data.value == 'rejected',
                  'badge badge-success text-wrap': data.value == 'accepted',
                }"
              >
                {{ data.value }}
              </div>
            </div>
          </template>
        </b-table>

      </div>
    </div>

  </div>
</template>

<script>
import mainClaim from "@/apis/Claim.js";
export default {
  name: "MainClaimPage",
  components: {},
  data() {
    return {
      claims: [],
      table_fields: [
        {
          key: "jobid",
          label: "Job id",
          sortable: true,
        },

        {
          key: "no",
          label: "Claim number",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Created",
          sortable: true,
          formatter: (value) => {
            return this.$store.getters.timestampAsDate(value);
          },
        },

        {
          key: "sentStatus",
          label: "sent status",
          sortable: true,
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    async getClaim() {
      var response = await mainClaim.mainClaim();
      this.claims = await this.addSendStatus(response.data.claims);
      //this.claims = await response.data.claims;
      //this.addSendStatus();
      //console.log("claims",this.claims);
    },

    tableClickedRow(item) {
      this.$router.push({
        path: `/claims/` + item.id,
        query: { id: "private" },
      });
    },

// TODO: This should be done backend. Not "fixed" frontend :)
    addSendStatus(response) {
      response[2].claim_accepted_at = 1; //these two needs to be removed
      response[3].claim_rejected_at = 1; //
      for (var i = 0; i < response.length; i++) {
        //console.log(response[i]);
        if (response[i].claim_created_at) {
          response[i].sentStatus = "Sent";
          //   response[i]._cellVariants= { sentStatus: 'info', }
        }
        if (response[i].claim_accepted_at) {
          response[i].sentStatus = "accepted";
          // response[i]._cellVariants= { sentStatus: 'success', }
        } else if (response[i].claim_rejected_at) {
          response[i].sentStatus = "rejected";
          //  response[i]._cellVariants= { sentStatus: 'danger', }
        }
      }
      return response;
    },
  },
  created() {
    //console.log(mainClaim.mainClaim());
    this.getClaim();
  },
  beforeCreate() {
    //this.getClaim();
  },
  computed: {},
};
</script>

<style scoped>
.bgColor {
  /*text-decoration-color: blue !important;*/
  background-color: blue !important;
}
</style>